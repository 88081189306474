import { first, map, mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { getUser } from '@auth/store/auth.selectors';
import { AppConfigService } from '@shared/services/app-config.service';
import { cloneDeep } from '@shared/utils';

@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private appConfigService: AppConfigService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const useRequestEncoding =
      this.appConfigService.configs && this.appConfigService.configs['use_request_encoding'] === 'true';
    return this.addToken(req).pipe(
      first(),
      mergeMap((requestWithToken: HttpRequest<any>) => {
        const encodedRequest =
          useRequestEncoding && !this.isAnException(req) ? this.encodeRequestBody(requestWithToken) : requestWithToken;
        return next.handle(encodedRequest).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const unprocessed =
                useRequestEncoding && !this.isAnException(req) ? this.decodeResponseBody(event) : event;
              return req.headers.has('Json-Format') ? unprocessed : this.processWidgetResponse(req, unprocessed);
            }
            return event;
          }),
        );
      }),
    );
  }

  private isAnException(req: HttpRequest<any>): boolean {
    const exceptions = ['version.json', 'configs.json', 'locale/en.json', './'];
    const url = req.url.toLowerCase();
    return exceptions.some((exception) => url.includes(exception));
  }

  private encodeRequestBody(req: HttpRequest<any>): HttpRequest<any> {
    const clonedBody = req.body instanceof FormData ? req.body : cloneDeep(req.body);
    const body = this.deepEncodeHtml(clonedBody);
    return req.clone({
      body,
      setHeaders: {
        'base64-encoding-used': 'true',
      },
    });
  }

  private decodeResponseBody(res: HttpResponse<any>): HttpResponse<any> {
    const body = this.deepDecodeBase64(res.body);
    return res.clone({ body });
  }

  private deepEncodeHtml(obj: any): any {
    if (typeof obj === 'string') {
      return this.encodeString(obj);
    } else if (Array.isArray(obj)) {
      return obj.map((item) => this.deepEncodeHtml(item));
    } else if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.deepEncodeHtml(obj[key]);
        }
      }
    }
    return obj;
  }

  private encodeString(str: string): string {
    const utf8Encoder = new TextEncoder();
    const utf8Bytes = utf8Encoder.encode(str);
    let binary = '';
    const len = utf8Bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(utf8Bytes[i]);
    }
    return btoa(binary);
  }

  private deepDecodeBase64(obj: any): any {
    if (typeof obj === 'string') {
      try {
        const binaryString = atob(obj);
        const utf8Decoder = new TextDecoder();
        const utf8Bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
        return utf8Decoder.decode(utf8Bytes);
      } catch (error) {
        return obj;
      }
    } else if (Array.isArray(obj)) {
      return obj.map((item) => this.deepDecodeBase64(item));
    } else if (typeof obj === 'object' && obj !== null) {
      if (obj.hasOwnProperty('meta')) {
        obj.meta = this.deepDecodeBase64(obj.meta);
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && key !== 'meta') {
          obj[key] = this.deepDecodeBase64(obj[key]);
        }
      }
    }
    return obj;
  }

  private processWidgetResponse(req: any, res: any): any {
    if (
      (req.method === 'POST' || req.method === 'PATCH' || req.method === 'PUT' || req.method === 'DELETE') &&
      res &&
      res.ok &&
      res.body &&
      res.url &&
      /widgets.*data/i.test(res.url) &&
      res.body.meta.widget
    ) {
      const bindings = res.body.meta.widget.data_configs.bindings;
      if (res.body.data.rows) {
        res.body.data.rows = res.body.data.rows.map((row: any) => {
          const item: any = {};
          Object.getOwnPropertyNames(bindings).forEach(
            (key) => (item[bindings[key].id] = row.values[bindings[key].params?.dest_index]),
          );
          return item;
        });
      } else {
        Object.values(res.body.data).forEach((data: any) => {
          if (!data?.rows?.length) {
            return;
          }

          data.rows = data.rows.map((row: any) => {
            const item: any = {};
            Object.getOwnPropertyNames(bindings).forEach(
              (key) => (item[bindings[key].id] = row.values[bindings[key].params.dest_index]),
            );
            return item;
          });
        });
      }
    }
    return res;
  }

  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    let customHeaders: any;

    return this.store.pipe(
      select(getUser),
      first(),
      mergeMap((user) => {
        if (user && user.token) {
          if (this.appConfigService.configs && this.appConfigService.configs['CUSTOM_HEADERS']) {
            customHeaders = this.appConfigService.configs['CUSTOM_HEADERS'];
            customHeaders['Token-Type'] = 'Bearer';
            customHeaders['Access-Token'] = user.token;
          }

          request = request.clone({
            setHeaders: customHeaders
              ? customHeaders
              : {
                  'Token-Type': 'Bearer',
                  'Access-Token': user.token,
                },
          });
        }
        return of(request);
      }),
    );
  }
}
